import React from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/styles';
import { FORM_STYLES, ESTABLISHMENT_TYPES } from './Constants';
import { connect } from 'react-redux';
import { addRequests } from '../Services/Redux/Requests/RequestsActions';
import { withSnackbar } from 'notistack';
import SuccessDialog from './SuccessDialog';

const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

class RequestForm extends React.Component {
    state = {
        name: null,
        email: null,
        establishmentname: null,
        establishmenttype: null,
        position: null,
        message: null,
        isDialogOpen: false,
    };

    handleChange = (event) => {
        const { target: { name, value } } = event;
        this.setState({ [name]: value })
    }
    
    closeDialog = () => {
        this.setState({ isDialogOpen: false })
    }

    handleRequest = async() => {
        try {
            const {isDialogOpen, ...input} = this.state;
            await this.props.addRequests(input);
            this.setState({ isDialogOpen: true })
        } catch (err) {
            this.props.enqueueSnackbar('An error occured. Please try again later.', { variant: 'error' });
            console.log(err);
        }
    }

    render() {
        const {
            name,
            email,
            establishmenttype,
            establishmentname,
            position,
            message,
            isDialogOpen
        } = this.state;

        return (
            <Grid container spacing={3} alignItems="stretch">
                <Grid item xs={12}>
                    <TextField
                        name="name"
                        label="Name *"
                        fullWidth
                        variant="outlined"
                        value={name}
                        onChange={this.handleChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        name="email"
                        label="Company Email *"
                        fullWidth
                        variant="outlined"
                        value={email}
                        onChange={this.handleChange}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        name="establishmenttype"
                        fullWidth
                        select
                        label="Establishment Type *"
                        value={establishmenttype}
                        onChange={this.handleChange}
                        variant="outlined"
                    >
                        {ESTABLISHMENT_TYPES.map(type => (
                            <MenuItem key={type} value={type}>
                                {type}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        name="establishmentname"
                        label="Establishment Name *"
                        fullWidth
                        variant="outlined"
                        value={establishmentname}
                        onChange={this.handleChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        name="position"
                        label="Designation"
                        fullWidth
                        variant="outlined"
                        value={position}
                        onChange={this.handleChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        name="message"
                        label="Message"
                        fullWidth
                        multiline
                        rows="4"
                        rowsMax="6"
                        variant="outlined"
                        value={message}
                        onChange={this.handleChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Button
                        fullWidth
                        color="primary"
                        variant="contained"
                        onClick={this.handleRequest}
                        size="large"
                        disabled={name===null ||
                            email===null ||
                            establishmentname===null ||
                            establishmenttype===null ||
                            !emailRegex.test(email)
                        }
                    >
                        Request
                    </Button>
                </Grid>
                <SuccessDialog
                    open={isDialogOpen}
                    handleClose={this.closeDialog}
                />
            </Grid>
        );
    }
}

const mapStateToProps = state => {
    return ({
        requests: state.requests,
    });
};

const mapDispatchToProps = dispatch => ({
    addRequests: (input) => dispatch(
        addRequests(input)
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)
(withStyles(FORM_STYLES)(withSnackbar(RequestForm)));