import React from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/styles';
import RequestForm from '../Components/RequestForm';

const styles = {
  content: {
      height: '100%'
  },
  image: {
    height: '50vh',
    backgroundSize: 'cover'
  },
  text: {
    padding: 40
  }
};

class Submit extends React.Component {

  render() {
    const { classes } = this.props;
    return (
        <Grid container>
          <Grid item xs={12} sm={6}>
            <div className={classes.text}>
              <h1>
                Volunteer in spreading kindness!
              </h1>
              <p>
                If you are affiliated with a company whose employees who are still needed
                out there in the field AND have the ability to <b>reach out to them virtually via mobile, email,
                or social media</b>, we need your help! Let's work together to make them know and feel
                that they are appreciated.
              </p>
              <p>
                We also need your help if you have the ability to reach out virtually or medically*
                to our COVID patients. Kindness can cure a lot of things.
              </p>
              <p>
                <b>PRINTING AND HANDING OUT PERSONALLY IS NOT ALLOWED.</b> We will not liable
                for any consequences who will not follow this policy. As much as we want it to feel more personal,
                we do not want to risk the further spread of COVID-19.
              </p>
              <p>
                We may be confined within our homes, but you can help us deliver
                all these kind thoughts to our front-liners. Please make sure to provide
                your email so we know how to coordinate with you.
              </p>
              <p>
                * People who can reach out medically are health workers who know how
                to properly sanitize and have professional protective gear.
              </p>
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className={classes.text}>
              <RequestForm />
            </div>
          </Grid>
        </Grid>
    );
  }
}

export default withStyles(styles)(Submit);
