const InitialStoreState = {
    auth: {
        user: null,
        error: false,
    },
    entries: {
        newId: 0,
        count: 0,
        data: []
    },
    requests: {
        newId: 0,
        count: 0,
        data: []
    },
};

export default InitialStoreState;