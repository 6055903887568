import Constants from './EntriesConstants';
import InitialStore from '../Store/InitialStore';

export default function storeReducer(state = InitialStore.entries, action = {}) {
    const { count: stateCount, newId: stateNewId, data: stateData } = state;

    switch (action.type) {
        case Constants.LIST_ENTRIES:
            const { count: dbCount, newId: dbNewId, data: dbData } = action.entries;
            return {
                count: dbCount,
                newId: dbNewId,
                data: dbData ? Object.values(dbData) : [],
            };
        case Constants.DELETE_ENTRY:
            return {
                count: stateCount - 1,
                newId: stateNewId,
                data: stateData.filter(item => item.index !== action.index)
            };
        case Constants.ADD_ENTRY:
            return {
                count: stateCount + 1,
                newId: stateNewId + 1,
                data: [...stateData, {index: stateNewId, ...action.entry}]
            };
        default:
        return state;
    }
};