import { FirebaseDb } from '../../Firebase';
import Constants from './RequestsConstants';
import { getTimeStampObject } from '../../../Utils/DateUtil';

const RequestsDbRef = FirebaseDb.ref('/requests');
const RequestsCountDbRef = FirebaseDb.ref('/requests/count');
const RequestsNewIdDbRef = FirebaseDb.ref('/requests/newId');

const getRequestsDbState = async () => {
  const snapshot = await RequestsDbRef.once('value');
  return snapshot.val();
};

export function listRequests() {
    return async dispatch => {
        const requests = await getRequestsDbState();
        dispatch({
          type: Constants.LIST_REQUESTS,
          requests
        });
        return requests;
    };
};

export function addRequests(input) {
  return async dispatch => {
      const snapshot = await RequestsNewIdDbRef.once('value');
      const index = snapshot.val();
      const countsnapshot = await RequestsCountDbRef.once('value');
      const count = countsnapshot.val();
      const RequestsDataPtDbRef = FirebaseDb.ref(`/requests/data/${index}`);
      const timestampObject = getTimeStampObject();
      const request = {
        index,
        ...input,
        ...timestampObject
      };
      RequestsDataPtDbRef.set(request);
      RequestsNewIdDbRef.set(index + 1);
      RequestsCountDbRef.set(count + 1);
      dispatch({
        type: Constants.ADD_REQUEST,
        request
      });
  };
};

export function deleteRequests(index) {
  return async dispatch => {
      const RequestsDataPtDbRef = FirebaseDb.ref(`/requests/data/${index}`);
      RequestsDataPtDbRef.remove();
      const countsnapshot = await RequestsCountDbRef.once('value');
      const count = countsnapshot.val();
      RequestsCountDbRef.set(count - 1);
      dispatch({
        type: Constants.DELETE_REQUEST,
        index
      });
  };
};