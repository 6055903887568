import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import CommonReducer from '../Commons/CommonReducer';
import { composeWithDevTools } from 'redux-devtools-extension';
import InitialStore from './InitialStore';

export default function configureStore(helpers) {
    const store = createStore(
        CommonReducer,
        InitialStore,
        composeWithDevTools(applyMiddleware(thunkMiddleware.withExtraArgument(helpers)))
    );
    return store;
};
