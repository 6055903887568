import { FirebaseDb } from '../../Firebase';
import Constants from './EntriesConstants';
import { handleImageUpload, handleImageRemoval } from '../Commons/CommonActions';
import { getTimeStampObject } from '../../../Utils/DateUtil';

const EntriesDbRef = FirebaseDb.ref('/entries');
const EntriesCountDbRef = FirebaseDb.ref('/entries/count');
const EntriesNewIdDbRef = FirebaseDb.ref('/entries/newId');

const getEntriesDbState = async () => {
  const snapshot = await EntriesDbRef.once('value');
  return snapshot.val();
};

export const handleEntriesUpload = async (imagefile, index) => {
  return await handleImageUpload(`images/entries/${index}`, imagefile);
};

export const handleEntriesRemoval = async (index) => {
  return await handleImageRemoval(`images/entries/${index}`);
};

export function listEntries() {
    return async dispatch => {
        const Entries = await getEntriesDbState();
        dispatch({
          type: Constants.LIST_ENTRIES,
          Entries
        });
        return Entries;
    };
};

export function addEntries(input) {
  return async dispatch => {
      const { imagefile, ...rest } = input;
      const indexsnapshot = await EntriesNewIdDbRef.once('value');
      const index = indexsnapshot.val();
      const countsnapshot = await EntriesCountDbRef.once('value');
      const count = countsnapshot.val();
      const imgsrc = imagefile ? await handleEntriesUpload(imagefile, index) : null;
      const EntriesDataPtDbRef = FirebaseDb.ref(`/entries/data/${index}`);
      const timestampObject = getTimeStampObject();
      const entry = {
        index,
        imgsrc,
        ...rest,
        ...timestampObject
      };
      EntriesDataPtDbRef.set(entry);
      EntriesNewIdDbRef.set(index + 1);
      EntriesCountDbRef.set(count + 1);
      dispatch({
        type: Constants.ADD_ENTRY,
        entry
      });
  };
};

export function deleteEntries(index) {
  return async dispatch => {
      const EntriesDataPtDbRef = FirebaseDb.ref(`/entries/data/${index}`);
      EntriesDataPtDbRef.remove();
      await handleEntriesRemoval(index);
      const countsnapshot = await EntriesCountDbRef.once('value');
      const count = countsnapshot.val();
      EntriesCountDbRef.set(count - 1);
      dispatch({
        type: Constants.DELETE_ENTRY,
        index
      });
  };
};