import { FirebaseStorage } from '../../Firebase';

export const handleImageUpload = async (refString, imagefile) => {
    const storageRef = FirebaseStorage.ref(refString);
    const uploadedUrl = await storageRef.put(imagefile)
        .then(() => storageRef.getDownloadURL()
            .then((snapshot) => snapshot));
    return uploadedUrl;
};

export const handleImageRemoval = async (refString) => {
    await FirebaseStorage.ref(refString).delete();
};