import Constants from './AuthContants';
import InitialStore from '../Store/InitialStore';

export default function storeReducer(state = InitialStore.auth, action = {}) {
  switch (action.type) {
    case Constants.SIGN_IN_SUCCESS:
      return {
        user: action.user,
        error: false,
      };
    case Constants.LOAD_CURRENT_USER:
      return {
        user: action.user,
        error: false,
      };
    case Constants.SIGNIN_ERROR:
      return {
        user: null,
        error: action.error,
      };
    case Constants.SIGNOUT_SUCCESS:
      return {
        user: null,
        error: false,
      };
    case Constants.SIGNOUT_ERROR:
      return {
        user: state.user,
        error: action.error,
      };
    default:
      return state;
  }
}
