import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from './Themes/Default';
import configureStore from './Services/Redux/Store/ConfigureStore'
import AppRenderer from './AppRenderer';

const store = configureStore();

function App() {
  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}>
        <Provider store={store}>
          <BrowserRouter>
            <AppRenderer />
          </BrowserRouter>
        </Provider>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
