import React from 'react';
import { withStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import Background from '../Assets/matheus-ferrero-yfmjALh1S6s-unsplash.jpg';

const styles = {
    content: {
        height: '100%'
    },
    image: {
      background: `url(${Background}) right bottom no-repeat`,
      height: '50vh',
      backgroundSize: 'cover',
      paddingRight: '15px !important',
      color: 'white',
      textAlign: 'right'
    },
    text: {
      padding: 40
    },
    mainmessage: {
      padding: 40,
      textAlign: 'left',
    }
};

class Home extends React.Component {
    render() {
        const { classes } = this.props;
        return (
            <Grid container spacing={3} alignItems="stretch" className={classes.content}>
                <Grid item xs={12} className={classes.image}>
                  Photo by Matheus Ferrero from Unsplash
                  <div className={classes.mainmessage}>
                    <h1>
                      "If something must be infectious, let it be kindness."
                    </h1>
                    <h5>
                      <i>- M. R. VALIMENTO</i>
                    </h5>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className={classes.text}>
                    <b>COVID-19</b> is a highly contagious disease that can be passed not only when breathing
                    in droplets of bodily fluids from an infected person. A new analysis says it can stay
                    suspended in the air for 3 hours and remain on surfaces upto 72 hours. Hence we are all
                    asked to stay at home, to reduce our risk of contracting the virus and spreading it even further.
                    But while we are serving our time in quarantine, we have modern day heroes out there who are
                    bravely trying to keep us all well-provisioned at home, saving lives directly or indirectly.
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} className={classes.text}>
                  <div className={classes.text}>
                    <b>Spread Kind Thoughts</b> is inspired by COVIDLetters -- an initiative by <i>Dr. Nicole Perreras</i> 
                    of the <i>Research Institute for Tropical Medicine, Philippines</i> where people can opt to send
                    mail to anonymous COVID patients in within their Hospital. We want to extend that to the front-liners who
                    might need some added inspiration on a daily basis, especially when the going gets really tough.
                  </div>
                </Grid>
            </Grid>
        );
    }     
}

export default withStyles(styles)(Home);