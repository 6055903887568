import React from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/styles';
import { FORM_STYLES, ESTABLISHMENT_TYPES } from './Constants';
import { connect } from 'react-redux';
import { addEntries } from '../Services/Redux/Entries/EntriesActions';
import { withSnackbar } from 'notistack';
import SuccessDialog from './SuccessDialog';

class EntryForm extends React.Component {
    state = {
        name: null,
        recipient: null,
        message: null,
        imagefile: null,
        isDialogOpen: false,
    };

    handleChange = (event) => {
        const { target: { name, value } } = event;
        this.setState({ [name]: value })
    }
    
    handleImageChange = (event) => {
        const { target: { name, files } } = event;
        this.setState({ [name]: files.length !== 0 ? files[0] : null });
    }

    closeDialog = () => {
        this.setState({ isDialogOpen: false })
    }

    handleSubmit = async() => {
        try {
            const {isDialogOpen, ...input} = this.state;
            await this.props.addEntries(input);
            this.setState({ isDialogOpen: true })
        } catch (err) {
            this.props.enqueueSnackbar('An error occured. Please try again later.', { variant: 'error' });
            console.log(err);
        }
    }

    render() {
        const { classes } = this.props;
        const { name, recipient, message, imagefile, isDialogOpen } = this.state;
        const imgsrc = imagefile ? URL.createObjectURL(imagefile) : null;

        return (
            <Grid container spacing={3} alignItems="stretch">
                <Grid item xs={12}>
                    <TextField
                        name="name"
                        label="Name"
                        fullWidth
                        variant="outlined"
                        value={name}
                        onChange={this.handleChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        name="message"
                        label="Message"
                        fullWidth
                        multiline
                        rows="4"
                        rowsMax="6"
                        variant="outlined"
                        value={message}
                        onChange={this.handleChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        name="recipient"
                        fullWidth
                        select
                        label="Recipient"
                        value={recipient}
                        onChange={this.handleChange}
                        variant="outlined"
                    >
                        <MenuItem key="Recipient..." value={null}>
                            Recipient...
                        </MenuItem>
                        <MenuItem key="COVID Patients" value="COVID Patients">
                            COVID Patients
                        </MenuItem>
                        {ESTABLISHMENT_TYPES.map(type => (
                            <MenuItem key={type} value={type}>
                                {type}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12}>
                    <Grid container justify="center" alignItems="center" className={classes.imagesection}>
                        <Grid item xs={12}>
                            <input
                                name="imagefile"
                                accept="image/*"
                                type="file"
                                onChange={this.handleImageChange}
                            />
                        </Grid>
                        {
                            imagefile &&
                            <Grid item className={classes.imagepreview} xs={12}>
                                <img src={imgsrc} alt="blog" className={classes.image}/>
                            </Grid>
                        }
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Button
                        fullWidth
                        color="primary"
                        variant="contained"
                        onClick={this.handleSubmit}
                        size="large"
                        disabled={imagefile===null && message===null}
                    >
                        Submit
                    </Button>
                </Grid>
                <SuccessDialog
                    open={isDialogOpen}
                    handleClose={this.closeDialog}
                />
            </Grid>
        );
    }
}

const mapStateToProps = state => {
    return ({
        entries: state.entries,
    });
};

const mapDispatchToProps = dispatch => ({
    addEntries: (input) => dispatch(
        addEntries(input)
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)
    (withStyles(FORM_STYLES)(withSnackbar(EntryForm)));