import React from 'react';
import {
  Switch,
  Route,
} from 'react-router-dom';
import { withStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import PublicRoutes from './AppRoutes';
import Menu from './Components/Menu';

const styles = {
    menu: {
        backgroundColor: 'black',
        padding: '20px !important',
        paddingTop: '50px !important',
        textAlign: 'center'
    },
    content: {
        height: '100%'
    }
};

class PublicRenderer extends React.Component {
    render() {
        const { classes } = this.props;
        return (
            <div className={classes.content}>
            <Grid container spacing={3} justify="center" className={classes.content}>
                <Grid item xs={12} className={classes.menu}>
                    <Menu routes={[...PublicRoutes]} />
                </Grid>
                <Grid item xs={12} className={classes.content}>
                    <Switch>
                        {
                            PublicRoutes.reverse().map(({ path, label, component }) =>(
                                <Route path={path} key={label}>
                                    {component}
                                </Route>
                            ))
                        }
                    </Switch>
                </Grid>
            </Grid>
            </div>
        );
    }     
}

export default withStyles(styles)(PublicRenderer);