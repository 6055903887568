import React from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/styles';
import EntryForm from '../Components/EntryForm';

const styles = {
  content: {
      height: '100%'
  },
  image: {
    height: '50vh',
    backgroundSize: 'cover'
  },
  text: {
    padding: 40
  }
};

class Submit extends React.Component {
  render() {
    const { classes } = this.props;
    return (
        <Grid container>
          <Grid item xs={12} sm={6}>
            <div className={classes.text}>
              <h1>
                Send some love to our COVID patients and front liners.
              </h1>
              <p>
                You can send us an artwork, or just a kind message -- we will look for ways to send them out.
                You can specify which group you want to send it to, or if your message is a bit generic, you can
                leave it to us. Not only will your efforts bring a smile to our front-liners' faces, it can also
                be a powerful bonding moments for you and your family.
              </p>
              <p>
                When we get enough submissions and maybe some reactions from our front-liners, we will add a
                newsfeed tab and feature some of your artworks and messages. Stay tuned!
              </p>
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className={classes.text}>
              <EntryForm />
            </div>
          </Grid>
        </Grid>
    );
  }
}

export default withStyles(styles)(Submit);
