export const FORM_STYLES = {
    paper: {
        padding: '1em'
    },
    paperlogin: {
        width: '500px',
        padding: '10px',
        display: 'inline-block',
        marginTop: '20px'
    },
    title: {
        textAlign: 'left',
        fontWeight: '700'
    },
    error: {
        color: '#CC0000'
    },
    imagesection: {
        height: '100%',
        border: '1px solid #BBB',
        borderRadius: '3px',
        padding: 15
    },
    imageuploader: {
        padding: '10px',
    },
    imagepreview: {
        paddingTop: '10px'
    },
    image: {
        maxHeight: '200px',
        maxWidth: '100%'
    }
};

export const ESTABLISHMENT_TYPES = [
    "Health Care",
    "Medical Supplies",
    "Food",
    "Transportation / Delivery",
    "Finance",
    "Security",
    "Information / News",
    "Maintenance / Cleaning",
    "Utilities",
    "Others"
];