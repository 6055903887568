import React from 'react';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import ButtonGroup from '@material-ui/core/ButtonGroup';

function Menu({ routes }) {
  return (
      <ButtonGroup variant="text" color="secondary">
          {
              routes.map(({ path, label }) => (
                  <Button component={Link} to={path}>
                      {label}
                  </Button>
              ))
          }
      </ButtonGroup>
  );
}

export default Menu;
