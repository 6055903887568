import React from 'react';
import Home from './Pages/Home';
import Submit from './Pages/Submit';
import Request from './Pages/Request';

const PublicRoutes = [
    {
        path: '/',
        label: 'Spread Kind Thoughts',
        component: <Home />
    },
    {
        path: '/submit',
        label: 'Send A Kind Thought',
        component: <Submit />
    },
    {
        path: '/register',
        label: 'Help Us Spread It',
        component: <Request />
    },
];

export default PublicRoutes;