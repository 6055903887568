import React from 'react';
import {
  Switch,
  Route,
  Redirect
} from 'react-router-dom';
import { connect } from 'react-redux';
import Login from './Pages/Login';
import Logout from './Pages/Member/Logout';
import PublicRenderer from './PublicRenderer';

class AppRenderer extends React.Component {
    render() {
        const { auth: { user } } = this.props;
        return (
            <Switch>
                <Route exact path="/login">
                    {
                        user !== null ?
                            <Redirect to="/admin/" />
                            : <Login />
                    }
                </Route>
                <Route path="/logout">
                    {
                        user === null ?
                            <Redirect to="/" />
                            : <Logout />
                    }
                </Route>
                <Route path="/logout">
                    {
                        user === null ?
                            <Redirect to="/" />
                            : <Logout />
                    }
                </Route>
                <Route path="/">                        
                    <PublicRenderer />
                </Route>
            </Switch>
        );
    }     
}

const mapStateToProps = state => {
    return ({
        auth: state.auth,
    });
};

export default connect(mapStateToProps)(AppRenderer);